/* eslint-disable @typescript-eslint/naming-convention */
import { CheckoutRequest, CheckoutResponse, Info, PrevAxios, PricingRequest, PricingResponse, StatusPixResponse, authGet, authPost, get, getCookie, post, put } from '@prev-front/api-adapter'
import qs from 'qs'
import { API_AUTH, API_HOST_DASHBOARD_V2, API_HOST_V1 } from "./constants"

interface ForgotOfficeData {
  username: string
}

interface LoginData {
  grant_type: string
  slug: string
  username: string
  password: string
}

export interface IOffice {
  id: number
  nome: string
  slug: string
  cnpj: string
}

export interface IToken {
  access_token: string
  expires_in: number
  refresh_token: string
  token_type: string
}

interface NewMemberProps {
  senha_nova: string
  token: string
}

export interface ResetPropsPasswordProps {
  senha_nova: string
  token: string
}

export const getSlug = async (slug: string): Promise<IOffice> => {
  const data: IOffice = await get(`/oauth/escritorios/${slug}`)
  return data
}

export const forgotOffice = async (data: ForgotOfficeData): Promise<void> => {
  await post('/oauth/escritorio/recupera', data)
}

export const login = async (data: LoginData): Promise<IToken> => {
  const authApp = `Basic ${API_AUTH}`
  const payload = {
    grant_type: data.grant_type || 'password',
    slug: data.slug,
    username: data.username,
    password: data.password
  }

  const response = await post('/oauth/token', qs.stringify(payload), {
    headers: {
      Authorization: authApp,
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
  return response as IToken
}

export const getUserInfo = async (): Promise<Info | null> => {
  const cookie = getCookie("prev_session");
  let prev_session = {};
  if (cookie) {
    prev_session = { "X-Global-Session-User-ID": cookie };
  }
  const response = await authGet('/info', API_HOST_DASHBOARD_V2, {
    headers: {
      ...prev_session
    }
  })
  return response
}

export const recoveryPassword = async (params: { slug: string, username: string }): Promise<void> => {
  await authPost({
    data: params,
    path: '/oauth/senha/recupera',
    host: API_HOST_V1
  })
}



export const resetPassword = async (params: ResetPropsPasswordProps): Promise<void> => {
  const form = {
    senha_nova: params.senha_nova,
    token: params.token
  }
  const formData = qs.stringify(form)
  await put({
    data: formData,
    path: '/oauth/senha',
    host: API_HOST_V1,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

export const validateAccount = async (cpf: string): Promise<void> => {
  await get(`/contas-valida?q=${cpf}`)
}

export const getPricing = async (props: PricingRequest): Promise<PricingResponse> => {
  const response = await PrevAxios({
    method: 'PUT',
    url: `${API_HOST_DASHBOARD_V2}/pricing`,
    data: props
  })
  return response.data
}

export const checkout = async (props: CheckoutRequest, recaptchaToken: string): Promise<CheckoutResponse> => {
  const cookie = getCookie('prev_session')
  let prev_session: Record<string, string> = {}
  if (cookie) {
    prev_session = { 'X-Global-Session-User-ID': cookie }
  }

  const response = await PrevAxios.post(
    `${API_HOST_DASHBOARD_V2}/checkout`,
    props,
    {
      headers: {
        'g-recaptcha-response': recaptchaToken,
        ...prev_session
      }
    }
  )
  return response.data as CheckoutResponse
}

export const getStatusPix = async (uuidPagamento: string, token: string): Promise<StatusPixResponse> => {
  const response = await PrevAxios.get(`${API_HOST_DASHBOARD_V2}/pagamentos/${uuidPagamento}/consulta-status`, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
  return response.data
}

export const newMember = async (props: NewMemberProps): Promise<void> => {
  const data = qs.stringify(props)

  const url = `${API_HOST_V1}${'/oauth/membro'}`;
  await PrevAxios.post(url, data, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  })
}