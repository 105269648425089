import React from 'react'
import * as ReactDOMClient from 'react-dom/client'
import singleSpaReact from 'single-spa-react'
import Root from './root.component'
import './styles/cssReset.css'

const lifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent: Root,
  errorBoundary (err, info, props) {
    console.log(err)
    return <div className="module-load-error"></div>
  }
})
export const { bootstrap, mount, unmount } = lifecycles
