import {
  PrevPlatformProvider,
  LoadingProvider
} from '@prev-front/ui-components'
import React, { Suspense } from 'react'
import { LoginProvider } from './context/LoginContext'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

const Login = React.lazy(
  () =>
    import(
      /* webpackChunkName: "login" */ './pages/Login'
    )
)

const Recovery = React.lazy(
  () =>
    import(
      /* webpackChunkName: "recovery" */ './pages/Recovery'
    )
)

const LoginWithoutOffice = React.lazy(
  () =>
    import(
      /* webpackChunkName: "loginWithoutOffice" */ './pages/LoginWithoutOffice'
    )
)

const ModalInvite = React.lazy(
  () =>
    import(
      /* webpackChunkName: "modalInvite" */ './pages/InviteOffice'
    )
)


const Checkout = React.lazy(
  () =>
    import(
      /* webpackChunkName: "checkout" */ './pages/Checkout'
    )
)


export default function Root (): JSX.Element {
  return (
    <PrevPlatformProvider>
      <LoadingProvider>
        <Suspense>
          <LoginProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/password-recovery/" element={<Recovery />} />
                <Route path="/invitation-accepted/" element={<ModalInvite />} />
                <Route path="/login/sem-escritorio" element={<LoginWithoutOffice />} />
                <Route path="/checkout" element={<GoogleReCaptchaProvider reCaptchaKey="6Lf0VccUAAAAANdGE1BkdpnSEnbb07RmUKLIP3KK" language="pt-BR">
                  <Checkout />
                </GoogleReCaptchaProvider>} />
              </Routes>
            </BrowserRouter>
          </LoginProvider>
        </Suspense>
      </LoadingProvider>
    </PrevPlatformProvider>
  )
}