// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    
}

body {
    display: flex;
    margin: 0;
    padding: 0;
    flex-direction: column;
}

:where(.css-dev-only-do-not-override-1bw5nkn).ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
    background-color: transparent;
}

:where(.css-dev-only-do-not-override-1bw5nkn).ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
    background-color: transparent;
}

:where(.css-dev-only-do-not-override-1bw5nkn).ant-input-outlined {
    background-color: transparent;
}

:where(.css-dev-only-do-not-override-1bw5nkn).ant-input-outlined.ant-input-status-error:not(.ant-input-disabled):hover {
    background-color: transparent;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-webkit-autofill:valid,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-transition-delay: 99999s;
}

.ant-steps.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
    max-width: 300px;
}`, "",{"version":3,"sources":["webpack://./src/styles/cssReset.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;;AAE1B;;AAEA;IACI,aAAa;IACb,SAAS;IACT,UAAU;IACV,sBAAsB;AAC1B;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,6BAA6B;AACjC;;;AAGA;;;;;;;;IAQI,gCAAgC;AACpC;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":["* {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n    \n}\n\nbody {\n    display: flex;\n    margin: 0;\n    padding: 0;\n    flex-direction: column;\n}\n\n:where(.css-dev-only-do-not-override-1bw5nkn).ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {\n    background-color: transparent;\n}\n\n:where(.css-dev-only-do-not-override-1bw5nkn).ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {\n    background-color: transparent;\n}\n\n:where(.css-dev-only-do-not-override-1bw5nkn).ant-input-outlined {\n    background-color: transparent;\n}\n\n:where(.css-dev-only-do-not-override-1bw5nkn).ant-input-outlined.ant-input-status-error:not(.ant-input-disabled):hover {\n    background-color: transparent;\n}\n\n\ninput:-webkit-autofill,\ninput:-webkit-autofill:hover,\ninput:-webkit-autofill:focus,\ninput:-webkit-autofill:active,\ninput:-webkit-autofill:valid,\nselect:-webkit-autofill,\nselect:-webkit-autofill:hover,\nselect:-webkit-autofill:focus {\n    -webkit-transition-delay: 99999s;\n}\n\n.ant-steps.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {\n    max-width: 300px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
