import React, { createContext } from 'react'
import useLogin, { type UseLoginInterface } from '../hooks/useLogin'

export const LoginContext = createContext<UseLoginInterface>({} as any)

export const LoginProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const login = useLogin()
  return (
        <LoginContext.Provider value={{ ...login }}>
            {children}
        </LoginContext.Provider>
  )
}
