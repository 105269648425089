import { useEffect, useState } from 'react'
import { getCookie, setCrossSubdomainCookie, ApiError, getToken } from '@prev-front/api-adapter'
import { getSlug, login, type IToken, forgotOffice, recoveryPassword, type ResetPropsPasswordProps, resetPassword } from '../services'
import { message } from '@prev-front/ui-components'
import { v4 as uuidv4 } from 'uuid'
import { removeSlugFromUrl, slugToUrl } from '../utils/checkoutUtils'

interface PassRecoveryFormProps {
  username: string
  slug: string
}

export interface UseLoginInterface {
  token: IToken | undefined
  slug: string | undefined
  setSlug: (slug: string) => void
  handleOffice: (office: string) => Promise<void>
  clearLogin: () => void
  handleLogin: (data: any) => Promise<void>
  recoverOffice: (data: RecoverOfficeInterface) => Promise<{ error: boolean }>
  passwordRecovery: (props: PassRecoveryFormProps) => Promise<void>
  handleResetPassword: (props: ResetPropsPasswordProps) => Promise<void>
}

interface RecoverOfficeInterface {
  username: string
}

const useLogin = (): UseLoginInterface => {
  const [slug, setSlug] = useState<string>()
  const [token, setToken] = useState<IToken>()

  useEffect(() => {
    const stringSlug = localStorage.getItem('slug')
    const stringToken = getToken()
    const slug: string = stringSlug ?? ''
    const token = stringToken ? stringToken : undefined
    if (token && stringSlug) setToken(token as unknown as IToken)
    if (slug) setSlug(slug)
    if (stringToken && !stringSlug) localStorage.removeItem('access_token_prev')
  }, [])

  const clearLogin = (): void => {
    setToken(undefined)
    removeSlugFromUrl()
  }

  const handleOffice = async (office: string): Promise<void> => {
    try {
      const slug = await getSlug(office)
      slugToUrl(slug.slug)
      storeSlug(slug.slug)
    } catch (err) {
      if (err instanceof ApiError) {
        void message.error(err.message)
        return
      }
      void message.error('Algo deu errado, tente novamente mais tarde')
    }
  }

  const storeSlug = (newSlug: string) => {
    const maxSlugs = 5
    const storedSlugs = localStorage.getItem("storedSlugs")
    const newStoredSlugs = storedSlugs ? JSON.parse(storedSlugs) : []
    const index = newStoredSlugs.indexOf(newSlug)
    if (index !== -1) {
      newStoredSlugs.splice(index, 1)
    }
    newStoredSlugs.unshift(newSlug)
    if (newStoredSlugs.length > maxSlugs) {
      newStoredSlugs.pop()
    }
  
    localStorage.setItem("storedSlugs", JSON.stringify(newStoredSlugs))
  }

  const handleLogin = async (data: any): Promise<void> => {
    try {
      const formatedData = {
        grant_type: slug ? "slug" : "password",
        slug: slug ?? '',
        username: data.username,
        password: data.password
      }
      if (formatedData && 'slug' in formatedData && !formatedData.slug) {
        /* @ts-expect-error asdasd */
        delete formatedData.slug
      }
      const response = await login(formatedData)
      localStorage.setItem('access_token_prev', response.access_token)
      const cookie = getCookie('prev_session')
      if (!cookie) setCrossSubdomainCookie('prev_session', uuidv4(), 365)
      setToken(response)
    } catch (err) {
      if (err instanceof ApiError) void message.error(err.message)
      if (!(err instanceof ApiError)) void message.error('Algo deu errado, tente novamente mais tarde')
    }
  }

  const recoverOffice = async (data: RecoverOfficeInterface): Promise<{ error: boolean }> => {
    try {
      await forgotOffice(data)
      void message.success('E-mail enviado com sucesso')
      return { error: false }
    } catch (err) {
      if (err instanceof ApiError) {
        void message.error(err.message)
        return { error: true }
      }
      void message.error('Algo deu errado, tente novamente mais tarde')
      return { error: true }
    }
  }

  const passwordRecovery = async (recoveryForm: PassRecoveryFormProps): Promise<void> => {
    const { slug, username } = recoveryForm
    try {
      await recoveryPassword({
        slug,
        username
      })
    } catch (err) {
      if (err instanceof ApiError) void message.error(err.message)
      if (!(err instanceof ApiError)) void message.error('Algo deu errado, tente novamente mais tarde')
      throw err
    }
  }

  const handleResetPassword = async (resetProps: ResetPropsPasswordProps): Promise<void> => {
      await resetPassword(resetProps)
  }

  return {
    token,
    slug,
    handleOffice,
    clearLogin,
    handleLogin,
    recoverOffice,
    passwordRecovery,
    handleResetPassword,
    setSlug
  }
}

export default useLogin
